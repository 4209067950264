@tailwind base;
@tailwind components;
@tailwind utilities;



/* .header{
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
}

.logo{
    width: 200px;
}



.nav-items > ul {
    font-size: 24px;
    display: flex;
    list-style-type: none;
}

.nav-items > ul > li {
    padding: 10px;
    margin: 10px;
}

.res-container{
    margin: 10px;
    display: flex;
    flex-wrap: wrap;
}

.res-card{
    background-color: #f0f0f0;
    margin: 40px;
    width: 250px;
}

.res-card:hover{
    cursor: pointer;
    border: 1px solid black;
}

.search{
    padding: 10px;
}

.res-logo{
    width: 100%;
}

.filter{
    margin: 5px;
}

.shimmer-container{
    display: flex;
    flex-wrap: wrap;
}

.shimmer-card{
    background-color: #f0f0f0;
    margin: 15px;
    width: 250px;
    height: 400px;
}

.login{
    margin-top:20px;
    height:25px;
    cursor: pointer;
}

.filter{
    display: flex;
}

.user-card{
    padding: 10px;
    border: 1px solid black;
} */